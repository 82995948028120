<template>
  <div class="container">
    <div class="nav">
      <p class="navTitle">基地列表</p>
      <el-tree :data="navList" node-key="id" ref="nodeTree" style="margin-left: 1vh;" :props="defaultProps"  @node-click="handleNodeClick"></el-tree>
    </div>
    <div class="main">
      <div class="mainHead">
        <div class="mainHeadLeft">
          起止时间
          <el-date-picker
              v-model="value1"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-button
              type="success"
              class="el-icon-search"
              @click="searchResult"
          ></el-button>
          <el-button type="success" @click="exportToExcel">导出</el-button>
        </div>
        <div class="mainHeadRight">
          <!-- <el-button type="success" icon="el-icon-refresh" size="mini"></el-button> -->
        </div>
      </div>
      <div class="echart" id="echart" v-loading="loading">
        <table class="history-table">
          <thead class="fixed-header">
            <tr>
              <th v-for="(item,i) in tableHeader" :key="i" v-html="changeText(item,i)"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in tableColumList" :key="i">
              <td :style="{'width': ii==0 || ii== 1 ? '10vw': 'auto'}" v-for="(iitm,ii) in item" :key="ii">{{ iitm }}</td>
            </tr>
          </tbody>
        </table>

      </div>
      <div class="pageBox">
        <el-pagination layout="total, sizes, prev, pager, next, jumper" :total="dataTotal" :current-page="pageNow"
                       :page-sizes="[10, 20, 50]" :page-size="pageSize" @size-change="siceChange"
                       @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
export default {
  data() {
    return {
      //value1: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date()],// 昨天和今天,
      value1: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      navList: [],
      deviceIndex: 0,
      defaultProps: {
        children: "children",
        label: "label",
      },
      page: 1,
      size: 100,
      dataTotal: 0,
      total: 0,
      // 当前页
      pageNow: 1,
      // 当前页数
      pageSize: 10,
      deviceNumber: "",
      deviceId: 0,
      tableDatas: [],
      lists: [],
      xlsxName: '',
      devList: [],
      allDevice: [],
      devListAll: [],
      devs: {},
      names: [],
      sensorNameList:[],
      sensorUnitList:[],
      tableColumList: [],
      tableHeader: [],
      loading: false
    };
  },
  watch: {
    /*navList: {
      handler(val) {
        if(val) {
          alert(val)
          this.$nextTick(() => {
            document.querySelector('.el-tree-node__content').click()
          })
        }
      },
      immediate: true
    }*/
  },
  mounted() {
    this.getTime();//默认一周时间
    this.getDeviceList();
    this.getBaseList();
  },
  methods: {

    exportToExcel() {
      // 将数据转换为工作表
      const worksheet = XLSX.utils.aoa_to_sheet(this.lists);
      // 创建工作簿并添加工作表
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      // 生成Excel文件
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      // 使用blob和FileReader来处理二进制数据
      const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      const dataUrl = URL.createObjectURL(dataBlob);
      // 使用saveAs来下载文件
      saveAs(dataBlob, this.xlsxName);
  },
// 序号
    indexMethod(index) {
      return index + this.pageSize * (this.pageNow - 1) + 1;
    },
    // 当前页数
    siceChange(val) {
      this.pageSize = val;
      // this.pageNow = 1;
      this.getRightData();
    },
    // 当前页
    currentChange(val) {
      this.pageNow = val;
      this.getRightData();
    },
    // 基地列表
    getBaseList(){
      this.$get("/baseManagement/getBaseManagements", {
        page: 1,
        size: 100
      }).then((res) => {
        if(res.data.state == 'success'){
          let datas = res.data.datas;
          for (let i = 0; i < datas.length; i++) {
            let ls=[...JSON.parse(datas[i].weatherDevice),...JSON.parse(datas[i].waterDevice),...JSON.parse(datas[i].soilDevice)]
            let ls2=[];
            for (let j = 0; j < ls.length; j++) {
              ls2.push(
                  {
                    label:this.allDevice[ls[j]].deviceName,
                    deviceNum:ls[j],
                  }
              )
            }
            this.navList.push({
              label:datas[i].baseName,
              id:datas[i].id,
              children:ls2
            })

            console.log("基地列表：",this.navList)
          }
        }
      })
    },
    // 获取节点列表
    getGetDeviceSensorList(number) {
      this.sensorNameList=[];
      this.$get('/deviceManage/getDeviceSensor/'+number, {
      }).then((res) => {
        if (res.data.state == 'success') {

          //清理  模拟量null名称
          let NameList=res.data.data.sensorNameStr?res.data.data.sensorNameStr.replace(/null\|/g, '').replace(/\|null/g, '').split('|'):"";
          this.sensorNameList=NameList

          //清理   模拟量null的数据值
          let sensorUnitStr=res.data.data.sensorUnitStr.replace(/null\|/g, '').replace(/\|null/g, '').replace(/null/g, '');
          this.sensorUnitList=["",""].concat(sensorUnitStr.split('|'));
          this.getRightData();
        }
      })
    },
    //获取设备列表-wuq
    getDeviceList(){
      this.$get("/deviceManage/getDeviceInfos", {
        page: 1,
        size: 1000,
      }).then((res) => {
        let ls=res.data.datas;
        for (let i = 0; i <ls.length; i++) {
          this.allDevice[ls[i].deviceNum]={
            id:ls[i].id,
            deviceName:ls[i].deviceName,
            realData:ls[i].realData,
            deviceLocation:ls[i].deviceLocation,
            deviceType:ls[i].deviceType,
            realTime:ls[i].realTime
          }
        }
      })
    },
    deviceClick(index, deviceNumber, deviceId) {
      this.deviceIndex = index;
      this.deviceNumber = deviceNumber;
      this.deviceId = deviceId;
      this.getGetDeviceSensorList(deviceNumber)


    },
    // 通过设备号获取数据
    getRightData() {
      let time = [];
      this.lists=[];
      if (this.value1.length > 0) {
        time = this.value1;
      }
      let time2 = time[0].split('-');
      let newTime1 = time2[0]+time2[1]+time2[2];
      let time3 = time[1].split('-');
      let newTime2 = time3[0]+time3[1]+time3[2];
      let time1 = " 00:00:00";
      this.loading = true
      this.$get("/historyDevice/getHistoryDevices", {
        page: this.pageNow,
        size: this.pageSize,
        limit:this.pageSize,
        deviceNum: this.deviceNumber,
        startYm: newTime1,
        endYm: newTime2,
      }).then((res) => {
        this.loading = false
        if (res.data.state == "success") {
          this.dataTotal=res.data.count;
          if (res.data.datas[0]) {
            let m = res.data.datas;
            if (m.length > 0) {
              this.tableDatas = res.data.datas;
              //console.log(this.tableDatas)
              this.lists = [['设备号', '采集时间'].concat(this.sensorNameList)];
              this.tableHeader = ['设备号', '采集时间'].concat(this.sensorNameList)
              const originArr = []
              for(let a = 0; a < this.tableDatas.length; a++){
                this.lists.push([this.tableDatas[a].deviceNum, this.tableDatas[a].createTime].concat(this.tableDatas[a].reportData.replace(/null\|/g, '').replace(/\|null/g, '').split("|")))
                originArr.push([this.tableDatas[a].deviceNum, this.tableDatas[a].createTime].concat(this.tableDatas[a].reportData.replace(/null\|/g, '').replace(/\|null/g, '').split("|")))
              }
              this.tableColumList = originArr
              this.xlsxName = this.tableDatas[0].deviceName + '数据表';
            } else {
              this.tableColumList = [];
            }
          } else {
            this.$message("暂无数据");
            this.tableColumList = [];
          }
        }
      });
    },
    changeText(item,i){
      if(this.sensorUnitList[i]){return item+"<br>("+this.sensorUnitList[i]+")"}else {
        return item;
      }

    },
    // 菜单点击事件
    handleNodeClick(e) {
      if(e.deviceNum){
        this.deviceNumber = e.deviceNum;
        this.getGetDeviceSensorList(e.deviceNum)
      }
    },
    // 点击搜索事件
    searchResult() {
      this.getRightData();
    },
    exportList() {
      let time = [];
      if (this.value1.length > 0) {
        time = this.value1;
      }
      let time1 = " 00:00:00";
      this.$get("/deviceExport/HistoryExportInformation", {
        deviceNumber: this.deviceNumber,
        // from: time[0] + time1,
        // to: time[1] + time1
      }).then((res) => {
        window.open(
            res.request.responseURL +
            "&from=" +
            time[0] +
            time1 +
            "&to=" +
            time[1] +
            time1
        );
      });
    },
    // 获取一周前的时间
    getTime() {
      var today = new Date();
      var year = today.getFullYear();
      var dateArr = [];
      for (var i = 0; i < 2; i++) {
        var newDate = new Date(today.getTime() - i * 1000 * 60 * 60 * 24);
        var month =
            parseInt(newDate.getMonth()) + 1 > 9
                ? parseInt(newDate.getMonth()) + 1
                : "0" + (parseInt(newDate.getMonth()) + 1);
        var day =
            newDate.getDate() > 9 ? newDate.getDate() : "0" + newDate.getDate();
        var fullDate = `${year}-${month}-${day}`;
        if (i == 1) {
          dateArr.push(fullDate);
        }
        if (i == 0) {
          dateArr.push(fullDate);
        }
      }
      this.value1 = dateArr.reverse();
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
.container {
  position: relative;
  width: 100%;
  height: 92vh;
  overflow: hidden;
  padding-left: 1vh;
  box-sizing: border-box;
  display: flex;
}
.nav {
  display: inline-block;
  width: 12vw;
  height: 85vh;
  margin-top: 1vh;
  margin-left: 1vh;
  background: #fff;
  border-radius: 10px;
  vertical-align: top;
  overflow-y: auto;
}
.navList {
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  text-align: center;
  cursor: pointer;
  margin-top: 1vh;
}
.navList.active {
  color: #009688;
}
.nav::-webkit-scrollbar {
  display: none;
}
.navTitle {
  margin-top: 2vh;
  font-size: 1.5vh;
  color: #409eff;
  padding-left: 1vh;
  box-sizing: border-box;
}
.el-tree {
  margin-top: 1vh;
}
.el-tree /deep/ .el-tree-node__label{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main {
  display: inline-block;
  width: 72vw;
  height: 85vh;
  margin-left: 1.5vh;
  margin-top: 1vh;
  background: #fff;
  border-radius: 10px;
  vertical-align: top;
  padding: 1vh;
  box-sizing: border-box;
}
.mainHead {
  width: 100%;
  height: 4vh;
}
.mainHeadLeft {
  display: inline-block;
  width: 60%;
  box-sizing: border-box;
}
.mainHeadRight {
  display: inline-block;
  width: 39%;
  text-align: right;
  vertical-align: middle;
}
.mainHeadRight i {
  font-size: 1.8vh;
}
.el-pagination {
  margin-top: 2vh;
}
#echart {
  width: 100%;
  margin-top: 3vh;
  height: 72vh;
  overflow-y: auto;
}
.pageBox {
  position: fixed;
  right: 5vh;
}
/* #echart /deep/ .el-table__body-wrapper {
  height: 72vh;
  overflow-y: auto;
} */
</style>
<style>
.container .el-table td,
.container .el-table th {
  text-align: center !important;
}
.history-table{
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  th{
    border-bottom: 0.00521rem solid #EBEEF5;
    background-color: rgba(240, 242, 245, 1) !important;
    height: 60px;
    color: #909399;
    font-size: 14px;
  }
  td{
    font-size:16px;
    background-color: #FFF;
    border-bottom: 1px solid #EBEEF5;
    height: 60px;
    color: #606266
  }
  .fixed-header{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
  }
}
</style>
